export const ufOptions = [
  { id: 1, name: 'Acre', value: 'AC' },
  { id: 2, name: 'Alagoas', value: 'AL' },
  { id: 3, name: 'Amapá', value: 'AP' },
  { id: 4, name: 'Amazonas', value: 'AM' },
  { id: 5, name: 'Bahia', value: 'BA' },
  { id: 6, name: 'Ceará', value: 'CE' },
  { id: 7, name: 'Distrito Federal', value: 'DF' },
  { id: 8, name: 'Espírito Santo', value: 'ES' },
  { id: 9, name: 'Goiás', value: 'GO' },
  { id: 10, name: 'Maranhão', value: 'MA' },
  { id: 11, name: 'Mato Grosso', value: 'MT' },
  { id: 12, name: 'Mato Grosso do Sul', value: 'MS' },
  { id: 13, name: 'Minas Gerais', value: 'MG' },
  { id: 14, name: 'Pará', value: 'PA' },
  { id: 15, name: 'Paraíba', value: 'PB' },
  { id: 16, name: 'Paraná', value: 'PR' },
  { id: 17, name: 'Pernambuco', value: 'PE' },
  { id: 18, name: 'Piauí', value: 'PI' },
  { id: 19, name: 'Rio de Janeiro', value: 'RJ' },
  { id: 20, name: 'Rio Grande do Norte', value: 'RN' },
  { id: 21, name: 'Rio Grande do Sul', value: 'RS' },
  { id: 22, name: 'Rondônia', value: 'RO' },
  { id: 23, name: 'Roraima', value: 'RR' },
  { id: 24, name: 'Santa Catarina', value: 'SC' },
  { id: 25, name: 'São Paulo', value: 'SP' },
  { id: 26, name: 'Sergipe', value: 'SE' },
  { id: 27, name: 'Tocantins', value: 'TO' },
];

export const legalNatureOptions = [
  { id: 1, name: '101-5 - Órgão Público do Poder Executivo Federal' },
  { id: 2, name: '102-3 - Órgão Público do Poder Executivo Estadual ou do Distrito Federal' },
  { id: 3, name: '103-1 - Órgão Público do Poder Executivo Municipal' },
  { id: 4, name: '104-0 - Órgão Público do Poder Legislativo Federal' },
  { id: 5, name: '105-8 - Órgão Público do Poder Legislativo Estadual ou do Distrito Federal' },
  { id: 6, name: '106-6 - Órgão Público do Poder Legislativo Municipal' },
  { id: 7, name: '107-4 - Órgão Público do Poder Judiciário Federal ' },
  { id: 8, name: '108-2 - Órgão Público do Poder Judiciário Estadual' },
  { id: 9, name: '110-4 - Autarquia Federal' },
  { id: 10, name: '111-2 - Autarquia Estadual ou do Distrito Federal' },
  { id: 11, name: '112-0 - Autarquia Municipal ' },
  { id: 12, name: '113-9 - Fundação Pública de Direito Público Federal' },
  { id: 13, name: '114-7 - Fundação Pública de Direito Público Estadual ou do Distrito Federal' },
  { id: 14, name: '115-5 - Fundação Pública de Direito Público Municipal' },
  { id: 15, name: '116-3 - Órgão Público Autônomo Federal' },
  { id: 16, name: '117-1 - Órgão Público Autônomo Estadual ou do Distrito Federal' },
  { id: 17, name: '118-0 - Órgão Público Autônomo Municipal' },
  { id: 18, name: '119-8 - Comissão Polinacional' },
  { id: 19, name: '121-0 - Consórcio Público de Direito Público (Associação Pública)' },
  { id: 20, name: '122-8 - Consórcio Público de Direito Privado' },
  { id: 21, name: '123-6 - Estado ou Distrito Federal' },
  { id: 22, name: '124-4 - Município' },
  { id: 23, name: '125-2 - Fundação Pública de Direito Privado Federal' },
  { id: 24, name: '126-0 - Fundação Pública de Direito Privado Estadual ou do Distrito Federal' },
  { id: 25, name: '127-9 - Fundação Pública de Direito Privado Municipal' },
  { id: 26, name: '128-7 - Fundo Público da Administração Indireta Federal' },
  {
    id: 27,
    name: '129-5 - Fundo Público da Administração Indireta Estadual ou do Distrito Federal',
  },
  { id: 28, name: '130-9 - Fundo Público da Administração Indireta Municipal' },
  { id: 29, name: '131-7 - Fundo Público da Administração Direta Federal' },
  { id: 30, name: '132-5 - Fundo Público da Administração Direta Estadual ou do Distrito Federal' },
  { id: 31, name: '133-3 - Fundo Público da Administração Direta Municipal' },
  { id: 32, name: '134-1 - União' },
  { id: 33, name: '201-1 - Empresa Pública' },
  { id: 34, name: '203-8 - Sociedade de Economia Mista' },
  { id: 35, name: '204-6 - Sociedade Anônima Aberta' },
  { id: 36, name: '205-4 - Sociedade Anônima Fechada' },
  { id: 37, name: '206-2 - Sociedade Empresária Limitada' },
  { id: 38, name: '207-0 - Sociedade Empresária em Nome Coletivo' },
  { id: 39, name: '208-9 - Sociedade Empresária em Comandita Simples' },
  { id: 40, name: '209-7 - Sociedade Empresária em Comandita por Ações' },
  { id: 41, name: '212-7 - Sociedade em Conta de Participação' },
  { id: 42, name: '213-5 - Empresário (Individual)' },
  { id: 43, name: '214-3 - Cooperativa' },
  { id: 44, name: '215-1 - Consórcio de Sociedades' },
  { id: 45, name: '216-0 - Grupo de Sociedades' },
  { id: 46, name: '217-8 - Estabelecimento, no Brasil, de Sociedade Estrangeira' },
  {
    id: 47,
    name: '219-4 - Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
  },
  { id: 48, name: '221-6 - Empresa Domiciliada no Exterior' },
  { id: 49, name: '222-4 - Clube/Fundo de Investimento' },
  { id: 50, name: '223-2 - Sociedade Simples Pura' },
  { id: 51, name: '224-0 - Sociedade Simples Limitada' },
  { id: 52, name: '225-9 - Sociedade Simples em Nome Coletivo' },
  { id: 53, name: '226-7 - Sociedade Simples em Comandita Simples' },
  { id: 54, name: '227-5 - Empresa Binacional' },
  { id: 55, name: '228-3 - Consórcio de Empregadores' },
  { id: 56, name: '229-1 - Consórcio Simples' },
  {
    id: 57,
    name: '230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
  },
  { id: 58, name: '231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples)' },
  { id: 59, name: '232-1 - Sociedade Unipessoal de Advogados' },
  { id: 60, name: '233-0 - Cooperativas de Consumo' },
  { id: 61, name: '234-8 - Empresa Simples de Inovação - Inova Simples' },
  { id: 62, name: '235-6 - Investidor Não Residente' },
  { id: 63, name: '303-4 - Serviço Notarial e Registral (Cartório)' },
  { id: 64, name: '306-9 - Fundação Privada' },
  { id: 65, name: '307-7 - Serviço Social Autônomo' },
  { id: 66, name: '308-5 - Condomínio Edilício' },
  { id: 67, name: '310-7 - Comissão de Conciliação Prévia' },
  { id: 68, name: '311-5 - Entidade de Mediação e Arbitragem' },
  { id: 69, name: '313-1 - Entidade Sindical' },
  { id: 70, name: '320-4 - Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras' },
  { id: 71, name: '321-2 - Fundação ou Associação Domiciliada no Exterior' },
  { id: 72, name: '322-0 - Organização Religiosa ' },
  { id: 73, name: '323-9 - Comunidade Indígena ' },
  { id: 74, name: '324-7 - Fundo Privado ' },
  { id: 75, name: '325-5 - Órgão de Direção Nacional de Partido Político' },
  { id: 76, name: '326-3 - Órgão de Direção Regional de Partido Político' },
  { id: 77, name: '327-1 - Órgão de Direção Local de Partido Político' },
  { id: 78, name: '328-0 - Comitê Financeiro de Partido Político' },
  { id: 79, name: '329-8 - Frente Plebiscitária ou Referendária' },
  { id: 80, name: '330-1 - Organização Social (OS)' },
  { id: 81, name: '331-0 - Demais Condomínios' },
  { id: 82, name: '332-8 - Plano de Benefícios de Previdência Complementar Fechada' },
  { id: 83, name: '399-9 - Associação Privada' },
  { id: 84, name: '401-4 - Empresa Individual Imobiliária' },
  { id: 85, name: '402-2 - Segurado Especial' },
  { id: 86, name: '408-1 - Contribuinte individual' },
  { id: 87, name: '409-0 - Candidato a Cargo Político Eletivo' },
  { id: 88, name: '411-1 - Leiloeiro ' },
  { id: 89, name: '412-0 - Produtor Rural (Pessoa Física)' },
  { id: 90, name: '501-0 - Organização Internacional' },
  { id: 91, name: '502-9 - Representação Diplomática Estrangeira' },
  { id: 92, name: '503-7 - Outras Instituições Extraterritoriais' },
];
export const activitiesOptions = [
  { id: 1, name: 'Indústria e/ou Comércio' },
  { id: 2, name: 'Indústria e/ou Comércio e Prestação de Serviços' },
  { id: 3, name: 'Prestação de Serviços' },
];

export const accountIntegrationOptions = [
  { id: 0, name: 'Nenhum', value: 'NENHUM' },
  { id: 1, name: 'Agili', value: 'AGILE' },
  { id: 2, name: 'Pentágono', value: 'PENTAGONO' },
];

export const itemTypes = [
  { name: 'TODOS', value: 'ALL' },
  { name: 'MATERIAL', value: 'MATERIAL' },
  { name: 'SERVIÇO', value: 'SERVICE' },
  { name: 'LOTE', value: 'LOT' },
];

export const passwordRules = [
  {
    id: 1,
    message: 'Insira ao menos uma letra maiúscula.',
    regex: /[A-Z]+/,
  },
  {
    id: 2,
    message: 'Insira ao menos uma letra minúscula.',
    regex: /[a-z]+/,
  },

  {
    id: 3,
    message: 'Deve conter ao menos um número.',
    regex: /[0-9]+/,
  },
  {
    id: 4,
    message: 'Deve conter entre 8 e 16 caracteres.',
    regex: /^.{8,16}$/,
  },
];

export const uploadLimit = 52428800;
