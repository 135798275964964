<template>
  <div>
    <div>
      <div v-if="!clarifications.length" class="panel">
        <div class="panel-block empty-panel">
          <section v-if="!clarifications.length" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nenhum esclarecimento.</p>
            </div>
          </section>
        </div>
      </div>

      <b-table
        hoverable
        detailed
        striped
        detail-key="id"
        v-if="clarifications.length"
        :data="clarifications"
        :opened-detailed="defaultOpenedDetails"
      >
        <b-table-column field="subject" label="Assunto" v-slot="props">
          {{ props.row.subject }}
        </b-table-column>

        <b-table-column centered field="status" label="Situação" width="100" v-slot="props">
          {{ props.row.status | status }}
          <b-tooltip
            class="situation-custom-tooltip"
            label="Esclarecimento sem resposta"
            type="is-primary"
            v-if="crier && props.row.status === 'CRIADO'"
          >
            <b-icon icon="alert-box" type="is-danger"></b-icon>
          </b-tooltip>
        </b-table-column>

        <b-table-column centered field="createdAt" label="Data" width="185" v-slot="props">
          {{ props.row.createdAt | formatDate }}
        </b-table-column>

        <template v-slot:detail="props">
          <AppMensagem
            :nome="props.row.question.name"
            :email="props.row.question.email"
            :data="new Date(props.row.createdAt)"
          >
            <div class="content message" v-html="props.row.question.message" />
          </AppMensagem>

          <AppMensagem
            :nome="props.row.response.name"
            :email="props.row.response.email"
            :data="new Date(props.row.response.date)"
            v-if="props.row.response.date"
          >
            <div class="content message" v-html="props.row.response.message" />
          </AppMensagem>

          <article class="media" v-if="crier && situation <= 3 && !props.row.response.date">
            <div class="media-content">
              <b-field
                label="Resposta"
                :type="campoInvalido('response', 'response') ? 'is-danger' : ''"
                :message="
                  campoInvalido('response', 'response') ? 'Resposta é um campo obrigatório' : ''
                "
              >
                <b-input type="textarea" v-model="$v.response.response.$model" />
              </b-field>
              <nav class="level">
                <div class="level-left">
                  <div class="level-item">
                    <b-button
                      :loading="loading"
                      type="is-primary send-button"
                      @click.prevent.stop="answerClarification(props.row)"
                      >Enviar</b-button
                    >
                  </div>
                </div>
              </nav>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <br />

    <div class="clarification" v-if="!company.buyer && canAsk">
      <h1 class="title is-4">Enviar Pergunta</h1>
      <form>
        <b-field grouped class="column-direction-touch">
          <b-field
            expanded
            label="Nome"
            :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
            :message="campoInvalido('name', 'data') ? 'Nome é um campo obrigatório' : ''"
          >
            <b-input
              custom-class="uppercase"
              :disabled="logged"
              v-model="$v.data.name.$model"
              @blur="event => converterMaiusculo('name', event.target.value, 'data')"
            />
          </b-field>
          <b-field
            v-if="logged"
            label="CNPJ"
            :type="campoInvalido('cnpj', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('cnpj', 'data')
                ? 'CNPJ é um campo obrigatório. Insira um número válido'
                : ''
            "
          >
            <b-input
              :disabled="logged"
              maxlength="18"
              :has-counter="false"
              v-mascara:cnpj
              v-model="$v.data.cnpj.$model"
            />
          </b-field>
          <b-field
            v-else
            label="CPF"
            :type="campoInvalido('cpf', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('cpf', 'data')
                ? 'CPF é um campo obrigatório. Insira um número válido'
                : ''
            "
          >
            <b-input
              :disabled="logged"
              maxlength="14"
              :has-counter="false"
              v-mascara:cpf
              v-model="$v.data.cpf.$model"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
          </b-field>
          <b-field
            expanded
            label="E-mail"
            :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
            :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
          >
            <b-input
              :disabled="logged"
              v-model="$v.data.email.$model"
              @input="data.email = data.email.toLowerCase()"
            />
          </b-field>
        </b-field>

        <b-field
          expanded
          label="Assunto"
          :type="campoInvalido('subject', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('subject', 'data') ? 'Assunto é um campo obrigatório' : ''"
        >
          <b-input v-model="$v.data.subject.$model" />
        </b-field>

        <b-field
          label="Pergunta"
          :type="campoInvalido('message', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('message', 'data') ? 'Pergunta é um campo obrigatório' : ''"
        >
          <b-input type="textarea" v-model="$v.data.message.$model" />
        </b-field>

        <button
          class="button is-primary send-button "
          :class="{ ' is-loading': loading }"
          @click.prevent.stop="createClarification"
        >
          Enviar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { isValidCpf } from '@brazilian-utils/validators';

import field from '@/mixins/formulario';
import { formatarData as formatDate } from '@/utils/format';
import { funcoes as functions } from '@/utils/enumerados';

import AppMensagem from '@/components/AppMensagem';

export default {
  name: 'AuctionClarifications',
  mixins: [field],
  components: {
    AppMensagem,
  },
  filters: {
    formatDate,
    status(status) {
      return `${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`;
    },
  },
  data() {
    const data = {
      cnpj: null,
      cpf: null,
      email: '',
      message: '',
      name: '',
      subject: '',
    };
    const response = {
      response: '',
    };

    return {
      data,
      defaultOpenedDetails: [],
      loading: false,
      response,
    };
  },
  computed: {
    ...mapState('auctionsClarifications', ['clarifications']),
    ...mapState('companies', ['company']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      deadlines: 'obterOfertaCompraPrazos',
      function: 'getFunction',
      logged: 'getLogged',
    }),
    canAsk() {
      const deadline = this.deadlines.find(elemento => elemento.id === 2);
      if (deadline) {
        return this.situation === 3 && deadline.data_ate >= Date.now();
      }
      return false;
    },
    crier() {
      return this.function === functions.PREGOEIRO;
    },
    situation() {
      return this.auction.situacao.id;
    },
  },
  watch: {
    company() {
      this.loadCompanyData();
    },
  },
  validations: {
    data: {
      cnpj: {
        required: requiredIf(function validarcnpj() {
          return this.company.cnpj;
        }),
      },
      cpf: {
        required: requiredIf(function validarcpf() {
          return !this.company.cnpj;
        }),
        validCpf() {
          if (!this.logged) return isValidCpf(this.data.cpf);
          return true;
        },
      },
      email: { email, required },
      message: { required },
      name: { required },
      subject: { required },
    },
    response: {
      response: { required },
    },
  },
  sockets: {
    'auction:clarifications': function updateClarifications(clarification) {
      this.updateAuctionClarifications(clarification);
      this.defaultOpenedDetails = this.defaultOpenedDetails.slice();
    },
  },
  methods: {
    ...mapActions('auctionsClarifications', [
      'createAuctionClarification',
      'answerAuctionClarification',
      'updateAuctionClarifications',
    ]),
    async answerClarification(clarification) {
      if (this.validar('response')) {
        this.loading = true;
        try {
          await this.answerAuctionClarification({
            auctionId: this.auction.id,
            clarificationId: clarification.id,
            data: this.response,
          });
          this.$alerta('Respondido', 'is-success');
          this.$v.data.$reset();
          this.limparCampos('response');
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    async createClarification() {
      if (this.validar('data')) {
        this.loading = true;
        try {
          await this.createAuctionClarification({
            auctionId: this.auction.id,
            data: { ...this.data, empresaId: this.company.id || null },
          });
          this.$alerta('Dados salvos', 'is-success');
          this.$v.data.$reset();
          this.limparCampos('data');
          if (this.company) this.loadCompanyData();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    loadCompanyData() {
      this.data.name = this.company.socialDenomination;
      this.data.cnpj = this.company.cnpj;
      this.data.email = this.company.email;
    },
  },
  created() {
    this.loadCompanyData();
  },
};
</script>

<style scoped>
.empty-panel {
  justify-content: center !important;
}

.message {
  white-space: pre-line;
}

.send-button {
  margin-top: 0.75rem;
  width: 150px;
}

.situation-custom-tooltip {
  vertical-align: middle;
}

@media (max-width: 1023px) {
  .send-button {
    width: 100%;
  }
}
</style>
