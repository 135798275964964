<template>
  <b-field :style="fieldGrow ? 'flex-grow: 1;' : 'flex-grow: 0;'" :label="label">
    <b-field>
      <p class="control">
        <span
          class="button is-static monetary-span"
          :class="{ 'monetary-span-color': !desabilitarCampos }"
          >R$</span
        >
      </p>
      <b-field
        :type="campoInvalido('valor') ? 'is-danger' : ''"
        :message="campoInvalido('valor') ? 'Valor inválido' : ''"
      >
        <b-input
          :custom-class="desabilitarCampos ? 'monetary monetary-input' : 'monetary'"
          v-if="exibirInput"
          ref="inputValor"
          :style="{ width: width + 'px' }"
          :disabled="desabilitarCampos"
          :placeholder="placeholder"
          v-mascara:valor.noPrefix.scale
          v-model="valoresMascara.valor"
          @input.native="evento => atualizarValorPersonalizado('valor', evento)"
          @keypress.native="teclasPermitidasValor($event)"
          @blur="evento => atualizarValorPersonalizado('valor', evento)"
        ></b-input>

        <div v-else class="control">
          <input
            class="button is-static monetary monetary-span"
            type="text"
            tabindex="-1"
            :class="{ 'monetary-span-color': !desabilitarCampos }"
            :placeholder="placeholder"
            :value="valoresMascara.valor"
            :style="{ width: width + 'px' }"
          />
        </div>
      </b-field>
    </b-field>
  </b-field>
</template>

<script>
import Big from 'big.js';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';
import { formatarValorSemPrefixo } from '@/utils/format';
import { calcularPorcentagemDeValor } from '@/utils/calculations';

export default {
  name: 'AppValor',
  props: {
    cancelarValidacao: { type: Boolean, default: true },
    calcularPercentual: { type: Boolean, default: false },
    carregarValor: { type: Boolean, default: false },
    desabilitarCampos: { type: Boolean, default: false },
    exibirInput: { type: Boolean, default: true },
    fieldGrow: { type: Boolean, default: false },
    label: { type: String, default: '' },
    item: { type: Object, default: () => {} },
    placeholder: { type: String, default: '' },
    valorValido: { type: Boolean, default: true },
    valores: { type: Object, default: () => {} },
    valorMonetario: { type: Number, default: 0 },
    width: { type: String, default: '' },
  },
  mixins: [campoMixin],
  data() {
    const dados = {
      valor: 0,
      percentual: 0,
    };

    const valoresMascara = {
      valor: '',
      percentual: '',
    };

    return {
      dados,
      valoresMascara,
    };
  },
  validations: {
    dados: {
      valor: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(999999999.9999),
        valorValido() {
          return this.valorValido && this.valoresMascara.valor !== '';
        },
      },
    },
  },
  watch: {
    valores() {
      if (this.valores.emissor !== 'valor' && this.valores.itemId === this.item.id) {
        if (this.valores.valorMascara !== '' && this.percentualValido) {
          this.dados.valor = this.valores.valor;
          this.valoresMascara.valor = formatarValorSemPrefixo(this.dados.valor);
        } else {
          this.dados.valor = 0;
          this.valoresMascara.valor = '';
        }
      }
    },
    carregarValor() {
      if (this.carregarValor) this.atualizarValorMonetario();
    },
  },
  computed: {
    percentualValido() {
      const valorPercentual = new Big(this.valores.percentual);

      if (!this.valorMonetario) {
        return valorPercentual.gt(0) && valorPercentual.lt(100);
      }

      return valorPercentual.gte(0) && valorPercentual.lt(100);
    },
  },
  methods: {
    atualizarValorMonetario() {
      this.valoresMascara.valor = formatarValorSemPrefixo(this.valorMonetario);
      this.dados.valor = this.valorMonetario;
    },
    atualizarValorPersonalizado(campo, evento) {
      const valor = Number(evento.target.vCleave.getRawValue());
      const valorFormatado = evento.target.vCleave.getFormattedValue();

      this.valoresMascara[campo] = valorFormatado;

      this.$v.dados[campo].$model = valor;
      this.$v.dados[campo].$touch();

      this.validarValores();

      this.$emit('valor', {
        ...this.dados,
        emissor: 'valor',
        valorMascara: this.valoresMascara.valor,
        itemId: this.item ? this.item.id : 0,
      });
    },
    validarValores() {
      if (this.valoresMascara.valor === '' && this.cancelarValidacao) {
        this.limparCampos();
        this.resetarEstado();
        return;
      }

      if (this.calcularPercentual) {
        const total = new Big(this.item.total);
        const valor = new Big(this.dados.valor);

        if (valor.gt(total)) {
          this.dados.percentual = 0;
          this.valoresMascara.percentual = '';
        }

        const valorCalculado = total.minus(valor);
        this.dados.percentual = calcularPorcentagemDeValor(this.item.total, valorCalculado);
      }
    },
    focus() {
      this.$refs.inputValor.focus();
    },
    formatarValor() {
      this.valoresMascara.valor = formatarValorSemPrefixo(this.dados.valor);
    },
    resetarEstado() {
      this.$v.dados.valor.$reset();
    },
    limparCampos() {
      this.dados.valor = 0;
      this.valoresMascara.valor = '';

      this.resetarEstado();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.carregarValor) {
        this.atualizarValorMonetario();
      }
    });

    this.$onBus('limparCampos', this.limparCampos);
    this.$onBus('resetarEstado', this.resetarEstado);
  },
};
</script>

<style lang="scss" scoped>
.monetary-span-color {
  color: #363636 !important;
}

.monetary-span {
  text-align: left;
  background-color: #ececec;
}

.field.is-grouped .field {
  flex-shrink: 1;
  flex-grow: 1;
}
</style>

<style lang="scss">
.monetary {
  border-radius: 0 4px 4px 0 !important;
}

.monetary-input {
  border-color: #dbdbdb !important;
  background-color: #ececec !important;
}
</style>
