/* eslint-disable import/prefer-default-export */
import { api } from './http';

export const todas = filters => {
  let params = '?';
  if (filters) {
    if (filters.status) {
      params += `status=${filters.status}&`;
    }
    if (filters.period) {
      if (filters.period.start) {
        params += `inicio=${filters.period.start.toISOString()}&`;
      }
      if (filters.period.end) {
        params += `fim=${filters.period.end.toISOString()}&`;
      }
    }
    if (filters.page) params += `pagina=${filters.page}&`;
    if (filters.perPage) params += `por_pagina=${filters.perPage}&`;
  }
  return api.get(`/validacao-empresa${params}`);
};

export const obter = empresaPendenteId => api.get(`/validacao-empresa/${empresaPendenteId}`);
export const validacoesEmpresa = empresaId => api.get(`/validacao-empresa/validacoes/${empresaId}`);
export const solicitarValidacao = empresaId =>
  api.get(`/validacao-empresa/${empresaId}/solicitar-validacao`);
export const atualizar = (empresaPendenteId, data) =>
  api.patch(`/validacao-empresa/${empresaPendenteId}`, data);

export const fetchLastValidation = companyId =>
  api.get(`/validacao-empresa/${companyId}/last-validation`);
